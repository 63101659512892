<!--
  @name: 证件照或者拍照直接上次组件
  @author: fengyanlong
  @date:2021-05-13
  @attr: {
    //返回照片地址
    v-model:{
      type: String
    }
  }
-->

<template>
  <div class="pictureInput-small" @click="open">
    <div class="picture" v-if="value">
      <img :src="value" alt="" />
    </div>
    <div class="imgno" v-else>
      <img
        src="https://local-qmp.oss-cn-shenzhen.aliyuncs.com/AppDatas/16529/1652950494249.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import { fileUploadBase64 } from "@/api/upload";
import { getCameraPicture, openCamera } from "../../../utils/hardware";

export default {
  name: "picture-input",
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    open() {
      if (this.value) {
        return;
      }
      let that = this;
      getCameraPicture(that.getPicture);
      openCamera();
    },
    getPicture(data64) {
      fileUploadBase64({ data: data64, ext: "png" })
        .then((res) => {
          this.showInfo = false;
          this.$emit("input", res.src);
        })
        .catch((err) => {
          console.log(err);
          this.$message.error("接口错误");
        });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../assets/style/custom.less";
.pictureInput-small {
  width: 66px;
  height: 66px;
  border-radius: 6px;
  overflow: hidden;
  .picture {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .imgno {
    width: 100%;
    height: 100%;
    background-color: #e8e8e8;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    img {
      width: 50%;
      height: 50%;
    }
  }
}
</style>
