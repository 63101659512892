/**
 * MS-产品中心接口 3.0
 */
import http from "../utils/fetch"; // 公共请求方法

const prefix = "/api/product"; // api前缀

//【product114】通过商户id获取项目列表接口
export const merchantProject = (data) => {
  return http.get(`${prefix}/merchant/project`, data);
};
//获取补差价产品
export const skuDifference = (data) => {
  return http.get(`${prefix}/sku/difference`, data);
};
// 获取景区无产品收银产品-胡汉杰
export function skuNoProduct(data) {
  return http.get(`${prefix}/sku/no_product`, data);
}
// 获取分时预约库存
export function appointmentStockDate(data) {
  return http.get(`${prefix}/appointment/stock/date`, data);
}
// 【product096】获取分类列表-胡汉杰
export const productMerchantCategoryList = (data) => {
  return http.get(`${prefix}/product/merchant/category/list`, data);
};
// 获取分时预约规则详情
export function getAppointmentTimeRule(data) {
  return http.get(`${prefix}/appointment/time/rule`, data);
}
// 【product105】获取分时预约库存信息-胡汉杰
export const appointmentTimeRuleStock = (data) => {
  return http.get(`${prefix}/appointment/time/rule/stock`, data);
};

//产品信息 http://192.168.1.50:40001/project/55/interface/api/3178
export const spuInfo = (data) => {
  return http.get(`${prefix}/product/spu/info`, data);
};
//保存热销产品列表-胡汉杰
export const hotProduct = (data) => {
  return http.post(`${prefix}/hot/product`, data);
};
//【product076】获取字段列表-胡汉杰
export function userinfoSettingInfoList(data) {
  return http.get(`${prefix}/userinfo/setting/info/list`, data);
}
