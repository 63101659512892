/**
 *@name: 搜索查询api
 *@date: 2021-11-09
 *@author: 易远胜
 *@version: 3.0
 */

import http from "../utils/fetch"; // 公共请求方法
const prefix = "/api/search";

// 后台-门票核销【search705】ok
export function orderTicketverify(data) {
  return http.get(`${prefix}/order/ticketverify`, data);
}

/**
 * 新版
 **/
// 窗口- 门票列表三【search108】
export const productFrontSilkyTicket = (data) => {
  return http.get(`${prefix}/product/front/silkyticket`, data);
};
// 窗口-组合产品列表二【search440】 http://192.168.1.50:40001/project/41/interface/api/3892
export const productFrontCombinationProduct = (data) => {
  return http.get(`${prefix}/product/front/combinationproduct`, data);
};
// 直接付款 门票补差价 票号查询 参数 ticketNo
export function searchTicketNumber(data) {
  return http.get(`${prefix}/order/ticketorder`, data);
}
//手机管家-产品详情(二)【search1207】- 李小华 http://192.168.1.50:40001/project/41/interface/api/3964
export const orderMobilemanagerProductdetails = (data) => {
  return http.get(`${prefix}/order/mobilemanager/productdetails`, data);
};
// 销售记录 http://192.168.1.50:40001/mock/41/api/search/order/salesrecord
export const orderSalesrecord = (data) => {
  return http.get(`${prefix}/order/salesrecord`, data);
};
// 核销记录
export const getVerificationrecord = (data) => {
  return http.get(`${prefix}/order/todayverification`, data);
};
// 核销记录 历史记录
export const getOldVerificationrecord = (data) => {
  return http.get(`${prefix}/order/verificationrecord`, data);
};
// 窗口-会员产品列表-李小华 http://192.168.1.50:40001/project/41/interface/api/3914
export const orderMemberProduct = (data) => {
  return http.get(`${prefix}/order/memberproduct`, data);
};
// 设置热销产品【search006】ok
export function productFrontHotproduct(data) {
  return http.get(`${prefix}/product/front/hotproduct`, data);
}
//窗口-会员资产套餐 http://192.168.1.50:40001/project/41/interface/api/3923
export const productFrontPropertyPackage = (data) => {
  return http.get(`${prefix}/product/front/propertypackage`, data);
};
//窗口-会员资产查询列表 http://192.168.1.50:40001/project/41/interface/api/3921
export function productFrontMemberProperty(data) {
  return http.get(`${prefix}/product/front/memberproperty`, data);
}
// 交账商户汇总【search1201】- 李小华  http://192.168.1.50:40001/project/41/interface/api/3794
export const orderAccountMerchantssummary = (data) => {
  return http.get(`${prefix}/order/account/merchantssummary`, data);
};
// 交账产品汇总【search1202】 http://192.168.1.50:40001/project/41/interface/api/3795
export const orderAccountProductsummary = (data) => {
  return http.get(`${prefix}/order/account/productsummary`, data);
};
//窗口- 交账产品汇总v2 http://192.168.1.50:40001/project/41/interface/api/3798
export const orderAccountConfirmsummary = (data) => {
  return http.get(`${prefix}/order/account/confirmsummary`, data);
};
// 窗口-餐饮结账单打印【search626】-云打印-http://192.168.1.50:40001/project/41/interface/api/2654
export const orderCateringPrint = (data) => {
  return http.get(`${prefix}/order/cateringprint`, data);
};
// 窗口-快速组合产品打印信息【search637】  http://192.168.1.50:40001/project/41/interface/api/3907
export const orderQuickCombinationPrint = (data) => {
  return http.get(`${prefix}/order/quickcombinationprint`, data);
};
// 窗口-快速门票打印信息【search636】 http://192.168.1.50:40001/project/41/interface/api/3905
export const orderQuickTicketPrint = (data) => {
  return http.get(`${prefix}/order/quickticketprint`, data);
};
// 新版窗口 取票列表 可查询 http://192.168.1.50:40001/project/41/interface/api/4159
export const orderTaketicketslist = (data) => {
  return http.get(`${prefix}/order/taketicketslist`, data);
};
// 窗口-子订单取票【search647】 可查询 http://192.168.1.50:40001/project/41/interface/api/4222
export const orderSubordertaketicket = (data) => {
  return http.post(`${prefix}/order/subordertaketicket`, data);
};
// 窗口-游乐列表【search109】 http://192.168.1.50:40001/project/41/interface/api/3903
export const productFrontAmusement = (data) => {
  return http.get(`${prefix}/product/front/amusement`, data);
};
// 窗口- 聚合产品分类【search117】 http://192.168.1.50:40001/project/41/interface/api/4270
export const productFrontAggregatecategory = (data) => {
  return http.get(`${prefix}/product/front/aggregatecategory`, data);
};
// 窗口-补办门票打印信息【search651】 http://192.168.1.50:40001/project/41/interface/api/4360
export const orderReissueticketprint = (data) => {
  return http.get(`${prefix}/order/reissueticketprint`, data);
};
