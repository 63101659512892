/**
 *@name: 调取硬件方法
 *@date: 2021-11-13
 *@author: 易远胜
 *@params: { Function } success 成功回调
 *@params: { Function } fail 失败回调
 */

// 被动扫码方法
export function scanCode(success, fail = () => {}) {
  try {
    window.scanCode = (res) => {
      success(res);
    };
    return true;
  } catch (error) {
    fail(error);
    return false;
  }
}

// 主动扫码方法
export function scanQRCode(success, fail = () => {}) {
  try {
    window.test.scanQRCode();
    window.scanCode = (res) => {
      success(res);
    };
    return true;
  } catch (error) {
    fail(error);
    return false;
  }
}
// 取消扫码监听方法
export function clearScanCode(fail = () => {}) {
  try {
    window.scanCode = () => {};
    return true;
  } catch (error) {
    fail(error);
    return false;
  }
}

// 语音提示
export function speak(success, fail = () => {}) {
  try {
    window.test.speak(success);
    return true;
  } catch (error) {
    fail(error);
    return false;
  }
}
// 读取身份证 信息
export function readIdCard(success = () => {}, fail = () => {}) {
  try {
    window.readIdCard = (res) => {
      success(res);
    };
    return true;
  } catch (error) {
    fail(error);
    return false;
  }
}

// 取消身份证监听方法
export function clearReadIdCard(fail = () => {}) {
  try {
    window.readIdCard = () => {};
    return true;
  } catch (error) {
    fail(error);
    return false;
  }
}

// 打印门票
export function printTicket(jsonStr, fail = () => {}) {
  try {
    window.test.printTicket(jsonStr);
    return true;
  } catch (error) {
    fail(error);
    return false;
  }
}

// 打印小票
export function printSmallTicket(jsonStr) {
  try {
    window.test.printSmallTicket(jsonStr);
    return true;
  } catch (error) {
    return false;
  }
}
/**
 * 获取摄像头返回的图像数据
 * @param fn 绑定一个函数接收拍摄结果
 */
export function getCameraPicture(fn) {
  try {
    window.getPicture = function (res) {
      fn(res);
    };
  } catch (error) {
    console.log("获取摄像头数据失败");
  }
}
/**
 * 开启摄像头
 */
export function openCamera() {
  try {
    window.test.openCamera();
  } catch (error) {
    console.log(error);
  }
}
/**
 * 打印交账模板
 */
export function printDailyReport(jsonStr) {
  try {
    window.test.printDailyReport(jsonStr);
  } catch (error) {
    console.log(error);
  }
}
