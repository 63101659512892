/**
 * @name:MS-订单接口-3.0
 * @date:2021-11-09
 * @description:新表模块
 **/
import http from "../utils/fetch"; // 公共请求方法
const prefix = "/api/order";

//手持机核销-参数：code
export const handTicketCheck = (data) => {
  return http.post(`${prefix}/hand/ticket/check`, data);
};

// order039-门票核销(后台)-云玉峰
export const apiOrderTicketCheckCode = (data) => {
  return http.post(`${prefix}/ticket/check`, data);
};

//order039-门票批量核销(后台)-云玉峰
export const apiOrderTicketCheckArray = (data, config) => {
  return http.post(`${prefix}/batch/ticket/check`, data, config);
};

// 通过项目消费编号获取会员信息-胡汉杰
export const itemConsumerMember = (data) => {
  return http.get(`${prefix}/item/consumer/member`, data);
};

// 项目核销接口-陈铭
export function itemWriteoff(data, config) {
  return http.post(`${prefix}/item/writeoff`, data, config);
}
// 【order128】极速下单 http://192.168.1.50:40001/project/41/interface/api/3891
export const createQuickOrder = (data) => {
  return http.post(`${prefix}/create/quick/order`, data);
};
// 订单-挂账支付
export const payOncreditOrder = (data) => {
  return http.post(`${prefix}/pay/oncredit/order`, data);
};
//order036-订单线下支付-云玉峰
export const payOfflineOrder = (data) => {
  return http.post(`${prefix}/pay/offline/order`, data);
};
//付款码支付-玉峰 （支付重构版本）
export const tradePaymentInfoMicropayV2 = (data, config) => {
  return http.get(`${prefix}/trade/payment/info/micropay/v2`, data, config);
};
// 订单-抵扣支付
export const payDeductionOrder = (data) => {
  return http.post(`${prefix}/pay/deduction/order`, data);
};
//获取支付状态-胡汉杰
export const tradePaymentInfoStatus = (data, config) => {
  return http.get(`${prefix}/trade/payment/info/status`, data, config);
};
// 根据订单号查询 报表数据
export const searchReportOrderDetails = (data) => {
  return http.get(`${prefix}/mini/report/order/detail`, data);
};
// 整单退款 http://192.168.1.50:40001/project/41/interface/api/3968
export const refundOrder = (data) => {
  return http.post(`${prefix}/refund/order`, data);
};
// 整单退款校验查询 http://192.168.1.50:40001/project/41/interface/api/3967
export const refundOrderCheck = (data) => {
  return http.post(`${prefix}/refund/order/check`, data);
};
// 强制整单退款 http://192.168.1.50:40001/project/41/interface/api/3974
export const refundOrderForce = (data) => {
  return http.post(`${prefix}/refund/order/force`, data);
};
// 产品退款校验查询 http://192.168.1.50:40001/project/41/interface/api/3965
export const refundProductCheck = (data) => {
  return http.post(`${prefix}/refund/product/check`, data);
};
// 产品退款 http://192.168.1.50:40001/project/41/interface/api/3966
export const refundProduct = (data) => {
  return http.post(`${prefix}/refund/product`, data);
};
// 强制产品退款 http://192.168.1.50:40001/project/41/interface/api/3972
export const refundProductForce = (data) => {
  return http.post(`${prefix}/refund/product/force`, data);
};
/**
 *核销
 */
// order039-门票核销(后台)-云玉峰
export const ticketCheck = (data) => {
  return http.post(`${prefix}/ticket/check`, data);
};
/**
 *核销
 */
// 新版订单--  order039-产品核销(后台)-云玉峰http://192.168.1.50:40001/project/41/interface/api/3982
export const productWriteOff = (data) => {
  return http.post(`${prefix}/product/writeoff`, data);
};
// 新版订单--   产品强制核销(后台)-云玉峰http://192.168.1.50:40001/project/41/interface/api/3983
export const productWriteOffForce = (data) => {
  return http.post(`${prefix}/product/writeoff/force`, data);
};
// 补录人脸接口 http://192.168.1.50:40001/project/41/interface/api/3977
export const additionalRecordinFace = (data) => {
  return http.post(`${prefix}/additional/recording/face`, data);
};
// 新版订单 修改预约 -http://192.168.1.50:40001/project/41/interface/api/3995
export const ticketChanging = (data) => {
  return http.post(`${prefix}/ticket/changing`, data);
};
// 新版订单 修改有效期 -http://192.168.1.50:40001/project/41/interface/api/3995
export const ticketValidTime = (data) => {
  return http.post(`${prefix}/ticket/valid/time`, data);
};
// 门票激活接口  http://192.168.1.50:40001/project/41/interface/api/3976
export const ticketActivite = (data) => {
  return http.post(`${prefix}/ticket/activite`, data);
};
// 补办前查询  http://192.168.1.50:40001/project/41/interface/api/4002
export const productReissueCheck = (data) => {
  return http.get(`${prefix}/product/reissue/check`, data);
};
//【order110】获取发票管理分页列表-刘院民  -Yapi
export const getInvoiceManage = (data) => {
  return http.get(`${prefix}/invoice/manage/show/pages`, data);
};
//【order110】获取发票管理分页列表-刘院民  -Yapi
export const getUserInfo = () => {
  return http.get(`${prefix}/find/handover/account/clock/in`);
};
export const orderHandoverMainInfoPage = (data) => {
  return http.get(`${prefix}/order/handover/main/info/page`, data);
};
//获取交账详情-商户汇总【窗口新版】- 刘院民  http://192.168.1.50:40001/project/41/interface/api/3801
export const orderHandoverMainInfo = (data) => {
  return http.get(`${prefix}/order/handover/main/info`, data);
};
//查询交账详情信息 - 产品汇总【窗口新版】（新版）- 刘院民  http://192.168.1.50:40001/project/41/interface/api/3802
export const orderHandoverAccountInfo = (data) => {
  return http.get(`${prefix}/order/handover/account/info`, data);
};
// 新版-订单详情-云玉峰 http://192.168.1.50:40001/project/41/interface/api/3939
export const orderDetailInfoNew = (data) => {
  return http.get(`${prefix}/order/detail/info`, data);
};
//order039-门票批量核销(后台)-云玉峰 http://192.168.1.50:40001/project/41/interface/api/4040
export const productWriteoffBatch = (data, config) => {
  return http.post(`${prefix}/product/writeoff/batch`, data, config);
};
//-订单下单后直接出票打印-云玉峰
export const orderPrint = (data) => {
  return http.post(`${prefix}/order/print`, data);
};
//查询产品的自定义打印规则前需要展示的信息 http://192.168.1.50:40001/project/41/interface/api/4189
export const findDefindPrintInfo = (data) => {
  return http.get(`${prefix}/find/defind/print/info`, data);
};
// 批量保存产品自定义规则修改后的信息 http://192.168.1.50:40001/project/41/interface/api/4192
export const saveDefindPrintInfo = (data) => {
  return http.post(`${prefix}/save/defind/print/info`, data);
};
// 窗口-快速零售打印信息【search649】 http://192.168.1.50:40001/project/41/interface/api/4276
export const orderQuickretailprint = (data) => {
  return http.get(`${prefix}/order/quickretailprint`, data);
};
//查询相关产品已核销列表 http://192.168.1.50:40001/project/41/interface/api/3947
export const quickOrderProductWrittenOffList = (data) => {
  return http.get(`${prefix}/quick/order/product/written/off/list`, data);
};
//查询相关产品押金金额 http://192.168.1.50:40001/project/41/interface/api/3926
export const quickOrderProductDepositAmount = (data) => {
  return http.post(`${prefix}/quick/order/product/deposit/amount`, data);
};
//修改游乐快速下单核销数据的状态 http://192.168.1.50:40001/project/41/interface/api/3934
export const updateQuickOrderWriteOffStatus = (data) => {
  return http.put(`${prefix}/update/quick/order/write/off/status`, data);
};
//产品消费计时结束退款 http://192.168.1.50:40001/project/41/interface/api/4273
export const timingFinishRefund = (data, config) => {
  return http.put(`${prefix}/timing/finish/refund`, data, config);
};
// 兑换批量核销(新版票务)-云玉峰 http://192.168.1.50:40001/project/41/interface/api/4336
export const productWriteOffStrictBatch = (data) => {
  return http.post(`${prefix}/product/writeoff/strict/batch`, data);
};
//兑换券支付 http://192.168.1.50:40001/project/41/interface/api/4369
export const payCouponOrder = (data) => {
  return http.post(`${prefix}/pay/coupon/order`, data);
};
// 今日核销数据
export const toDaywriteOffList = (data) => {
  return http.get(`${prefix}/checker/detail/writeoff/info`, data);
};
//清账查询 http://192.168.1.50:40001/project/41/interface/api/4387
export const findOncreditOrderInfo = (data) => {
  return http.get(`${prefix}/find/oncredit/order/info`, data);
};
//清账 http://192.168.1.50:40001/project/41/interface/api/4384
export const settleOncreditOrder = (data) => {
  return http.post(`${prefix}/settle/oncredit/order`, data);
};
//清账查询 http://192.168.1.50:40001/project/41/interface/api/4417
export const findOncreditOrderList = (data) => {
  return http.get(`${prefix}/find/oncredit/order/list`, data);
};
