<!--
  @name: 采集人脸组件
  @author: fengyanlong
  @attr: {
    //返回人脸信息id： personId
    v-model:{
      type: String
    },
    echoimgurl: 人脸照片回显
  }
  @events: imgUrlChange 返回拍照后的人脸在线图片
-->
<template>
  <div class="faceInput" @click="openCamera">
    <div v-if="imgUrl" class="picture">
      <img :src="imgUrl" alt="图片显示失败" />
    </div>
    <div v-else class="imgno">
      <img
        src="https://local-qmp.oss-cn-shenzhen.aliyuncs.com/AppDatas/16529/1652950494249.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import { faceAnalyzeUpload, face } from "@/api/iot";
import { getCameraPicture, openCamera } from "../../../utils/hardware";
export default {
  name: "faceInput",
  props: ["value", "echoimgurl", "faceid"],
  data() {
    return {
      imgUrl: "",
      imgbase64: "",
    };
  },
  watch: {
    echoimgurl: {
      handler(newVal) {
        if (newVal) {
          this.imgUrl = newVal;
        }
      },
      immediate: true,
    },
  },
  created() {},
  methods: {
    openCamera() {
      let that = this;
      getCameraPicture(that.getPicture);
      openCamera();
    },
    getPicture(data64) {
      this.imgbase64 = `data:image/png;base64,${data64}`;
      faceAnalyzeUpload({ imageBase64: this.imgbase64 })
        .then((res) => {
          this.saveFace(res.src);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    saveFace(url) {
      //type：1是会员，0是员工
      let postData = { imageUrl: url, type: 1 };
      //补录人脸
      if (this.faceid) {
        postData.lastPersionId = this.faceid;
      }
      face(postData)
        .then((res) => {
          this.imgUrl = url;
          this.$emit("input", res.personId);
          this.$emit("imgUrlChange", url);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../assets/style/custom.less";

.faceInput {
  overflow: hidden;
  border-radius: 6px;
  width: 66px;
  height: 66px;
  text-align: center;
  cursor: pointer;
  .picture {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .imgno {
    width: 100%;
    height: 100%;
    background-color: #e8e8e8;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    img {
      width: 50%;
      height: 50%;
    }
  }
}
</style>
