/**
 * MS-硬件管理
 */
import http from "../utils/fetch"; // 公共请求方法
let prefix = "/api/iot"; // api前缀

// 人脸质量分析-胡汉杰
export const faceAnalyze = (data) => {
  return http.post(`${prefix}/face/analyze`, data);
};

// 创建人脸-胡汉杰
export const face = (data) => {
  return http.post(`${prefix}/face`, data);
};

// 校验并上传人脸-胡汉杰
export const faceAnalyzeUpload = (data) => {
  return http.post(`${prefix}/face_analyze/upload`, data);
};

// 【iot38】查询对应版本信息列表- 刘院民
export const iotClientInstallPackageVersionList = (data) => {
  return http.get(`${prefix}/iot/client/install/package/version/list`, data);
};

// 【Iot002】客户端文件列表 - 马鹏浩
export const listIotClientInstallPackage = (data) => {
  return http.get(`${prefix}/list/iot/client/install/package`, data);
};

//【iot46】取号打印接口 - 刘院民  http://192.168.1.50:40001/project/41/interface/api/3623
export const cloudPrintTakeANumber = (data) => {
  return http.post(`${prefix}/cloud/print/take/a/number`, data);
};
