/**
 * MS-会员接口 3.0
 **/
import http from "../utils/fetch"; // 公共请求方法
const prefix = `/api/cart`; //服务前缀

// 加入购物车
export const addTocart = (data) => {
  return http.post(`${prefix}/common/no/product`, data);
};
//添加票务产品到购物车
export const cartTicket = (data) => {
  return http.post(`${prefix}/ticket`, data);
};
//更新产品到购物车
export const ticketMore = (data) => {
  return http.post(`${prefix}/ticket/more`, data);
};
// 门票激活接口，查询门票需要填写的出游人信息  http://192.168.1.50:40001/project/41/interface/api/3997
export function activateTravellerInfo(data) {
  return http.get(`${prefix}/activate/traveller/info`, data);
}
// 门票补办  http://192.168.1.50:40001/project/41/interface/api/4004
export function commonReissue(data) {
  return http.post(`${prefix}/common/reissue`, data);
}
// 新的会员充值 http://192.168.1.50:40001/project/41/interface/api/3951
export const memberRecharge = (data) => {
  return http.post(`${prefix}/member/recharge`, data);
};
//查询加购产品列表 http://192.168.1.50:40001/project/41/interface/api/3916
export const getTicketAddBuy = (data) => {
  return http.get(`${prefix}/ticket/add/buy`, data);
};
//修改加购数量 http://192.168.1.50:40001/project/41/interface/api/3901
export const postTicketAddBuy = (data) => {
  return http.post(`${prefix}/ticket/add/buy`, data);
};
//惠民政策，通过目标产品找到多个特惠产品让前端选择
export const selectDiscountSku = (data) => {
  return http.get(`${prefix}/select/discount/sku`, data);
};
//修改使用日期 http://192.168.1.50:40001/project/41/interface/api/3898
export const ticketDate = (data) => {
  return http.post(`${prefix}/ticket/date`, data);
};
//更新购物车时段 http://192.168.1.50:40001/project/41/interface/api/3899
export const ticketAppointmentDate = (data) => {
  return http.post(`${prefix}/ticket/appointment/date`, data);
};
//更新快票开关 http://192.168.1.50:40001/project/41/interface/api/3908
export const ticketFast = (data) => {
  return http.post(`${prefix}/ticket/fast`, data);
};
//更新出游人信息 http://192.168.1.50:40001/project/41/interface/api/3900
export const ticketTraveller = (data) => {
  return http.post(`${prefix}/ticket/traveller`, data);
};
// 修改打印规则 http://192.168.1.50:40001/project/41/interface/api/3945
export const commonPrintWay = (data) => {
  return http.post(`${prefix}/common/print/way`, data);
};
// 修改打印规则产品列表接口 http://192.168.1.50:40001/project/41/interface/api/3944
export const commonPrintProductList = (data) => {
  return http.get(`${prefix}/common/print/product/List`, data);
};
// 取单列表 展示购物车商品 列表 （不展示当前正在展示的） http://192.168.1.50:40001/project/41/interface/api/3929
export const commonCartList = (data) => {
  return http.get(`${prefix}/common/cart/list`, data);
};
//挂单 http://192.168.1.50:40001/project/41/interface/api/3931
export const commonPauseCart = (data) => {
  return http.post(`${prefix}/common/pause/cart`, data);
};
//删除取单列表 http://192.168.1.50:40001/project/41/interface/api/3932
export const delPauseCart = (data) => {
  return http.post(`${prefix}/common/delete/cart`, data);
};
// 取单 http://192.168.1.50:40001/project/41/interface/api/3930
export const commonTakeOut = (data) => {
  return http.get(`${prefix}/common/take/out`, data);
};

// 窗口端新版修改销售价 http://192.168.1.50:40001/project/41/interface/api/3938
export const commonChangePrice = (data) => {
  return http.post(`${prefix}/common/change/price`, data);
};
// 修改会员或者会员密码 http://192.168.1.50:40001/project/41/interface/api/3948
export const commonMember = (data) => {
  return http.post(`${prefix}/common/member`, data);
};
// 票务 - 小程序多个游客 http://192.168.1.50:40001/project/41/interface/api/4147
export const ticketCommonlyVisitorBatch = (data) => {
  return http.post(`${prefix}/ticket/commonly/visitor/batch`, data);
};

// 组合产品 - 添加购物车，参数和票务一样 http://192.168.1.50:40001/project/41/interface/api/3986
export const combination = (data) => {
  return http.post(`${prefix}/combination`, data);
};
// 组合产品 - 增加新产品和改数量 http://192.168.1.50:40001/project/41/interface/api/3987
export const combinationMore = (data) => {
  return http.post(`${prefix}/combination/more`, data);
};
// 组合产品 - 修改使用日期 http://192.168.1.50:40001/project/41/interface/api/3989
export const combinationDate = (data) => {
  return http.post(`${prefix}/combination/date`, data);
};
// 组合产品 - 修改选择时段 http://192.168.1.50:40001/project/41/interface/api/3990
export const combinationAppointmentDate = (data) => {
  return http.post(`${prefix}/combination/appointment/date`, data);
};
// 组合产品 - 组合产品 - 打开或关闭快票 http://192.168.1.50:40001/project/41/interface/api/3991
export const combinationFast = (data) => {
  return http.post(`${prefix}/combination/fast`, data);
};
// 组合产品 - 修改单个出游人的单个信息 http://192.168.1.50:40001/project/41/interface/api/3992
export const combinationTraveller = (data) => {
  return http.post(`${prefix}/combination/traveller`, data);
};
// 组合产品 - 修改加购产品的数量 http://192.168.1.50:40001/project/41/interface/api/3993
export const combinationAddBuy = (data) => {
  return http.post(`${prefix}/combination/add/buy`, data);
};
// 删除整个购物车 http://192.168.1.50:40001/project/41/interface/api/3932
export const commonDeleteCart = (data) => {
  return http.post(`${prefix}/common/delete/cart`, data);
};
// 一产品一游客组合 - 小程序多个游客 http://192.168.1.50:40001/project/41/interface/api/4150
export const combinationCommonlyVisitorBatch = (data) => {
  return http.post(`${prefix}/combination/commonly/visitor/batch`, data);
};

//游乐
//游乐 - 添加购物车 http://192.168.1.50:40001/project/41/interface/api/4162
export const cartPlay = (data) => {
  return http.post(`${prefix}/play`, data);
};
//游乐 - 更新购物车 http://192.168.1.50:40001/project/41/interface/api/4165
export const playMore = (data) => {
  return http.post(`${prefix}/play/more`, data);
};
//游乐 - 修改使用日期 http://192.168.1.50:40001/project/41/interface/api/4168
export const playDate = (data) => {
  return http.post(`${prefix}/play/date`, data);
};
//游乐 - 修改选择时段 http://192.168.1.50:40001/project/41/interface/api/4171
export const playAppointmentDate = (data) => {
  return http.post(`${prefix}/play/appointment/date`, data);
};
// 通用查询购物车详情-刘资涌 http://192.168.1.50:40001/project/41/interface/api/3984
export const cartCommonDetail = (data) => {
  return http.get(`${prefix}/common/detail`, data);
};
//票务和组合 - 批量给产品添加加购 http://192.168.1.50:40001/project/41/interface/api/4237
export const commonAddBuyBatch = (data) => {
  return http.post(`${prefix}/common/add/buy/batch`, data);
};
