/**
 * MS-会员接口 3.0
 **/
import http from "../utils/fetch"; // 公共请求方法
const prefix = `/api/member`; //服务前缀

// 查询会员详情基本信息 - 罗子健 http://192.168.1.50:40001/project/41/interface/api/3904
export const orderOneMemberInfo = (data) => {
  return http.get(`${prefix}/evidence/one/member`, data);
};
// 查询会员详情基本信息 - 罗子健 http://192.168.1.50:40001/project/41/interface/api/3904
export const evidenceOneMemberInfo = (data) => {
  return http.get(`${prefix}/evidence/one/member`, data);
};
// 【member005】资产记录 -刘资涌
export function balanceRecordInfo(data) {
  return http.get(`${prefix}/balance/record/info`, data);
}
// 【member006】积分记录 -刘资涌
export function integralRecordInfo(data) {
  return http.get(`${prefix}/integral/record/info`, data);
}
// 会员-根据会员id查询会员资产记录(充值退款明细列表)-罗子健 http://192.168.1.50:40001/project/41/interface/api/3913
export const findMemberAssetsInfo = (data) => {
  return http.get(`${prefix}/find/member/assets/info`, data);
};
// 退款弹窗信息-罗子健 - 罗子健 http://192.168.1.50:40001/project/41/interface/api/4001
export const memberRefundBalance = (data) => {
  return http.get(`${prefix}/member/refund/balance`, data);
};
// 会员详情单个资产退款-罗子健 http://192.168.1.50:40001/project/41/interface/api/3958
export const memberAssetsInfoMini = (data) => {
  return http.post(`${prefix}/member/assets/info/mini`, data);
};

// // 根据身份证过滤信息 查询游客分类列表(窗口使用)-许家炜 -最新 http://192.168.1.50:40001/project/41/interface/api/4039
export const touristInfoBycard = (data) => {
  return http.get(`${prefix}/new/select/tourist/info/by/card`, data);
};
// 根据会员凭证返回会员封装后信息 http://192.168.1.50:40001/project/41/interface/api/4032
export const evidenceOneMemberVisitor = (data) => {
  return http.get(`${prefix}/evidence/one/member/visitor`, data);
};
// 会员-查询会员资产明细-罗子健 http://192.168.1.50:40001/project/41/interface/api/3911
export const findMemberProperty = (data) => {
  return http.get(`${prefix}/find/member/property`, data);
};
//检验用户支付密码是否正确 - 罗子健 暂无api文档
export const checkMemberPasswordInfo = (data) => {
  return http.get(`${prefix}/cart/check/member/password/info`, data);
};
// 根据会员信息、购物车id返回我的产品,我的兑换券,我的兑换币-罗子健 http://192.168.1.50:40001/project/41/interface/api/4246
export const memberPaymentInformation = (data) => {
  return http.post(`${prefix}/verify/member/payment/information`, data);
};
